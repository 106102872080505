import React from "react"
import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"

import Layout from "../components/Layout/Layout"
import PostRow from "../components/PostRow/PostRow"
import SEO from "../components/seo"

import styles from "./tags-template.module.css"

const TagsTemplate = ({ data, pageContext, location }) => {
  const { tag } = pageContext
  const { edges } = data.allMdx
  const pageTitle = `Posts about "${tag}"`

  return (
    <Layout location={location} headerAttrs={{ tag: tag }}>
      <SEO
        title={pageTitle}
        description={`Archive for posts aboout ${tag}.`}
        keywords={`${tag}, posts, archives, keycloak themes, keycloakthemes.com`}
      />

      <main className={styles.main}>
        {edges.map(({ node }) => (
          <PostRow key={node.slug} post={node} />
        ))}

        <div className={styles.viewAll}>
          <Link to="/tags" title="View all topics">
            View all topics
          </Link>
        </div>
      </main>
    </Layout>
  )
}

TagsTemplate.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.object.isRequired,
          description: PropTypes.string,
        })
      ),
    }),
  }),
}

export default TagsTemplate

export const pageQuery = graphql`
  query($tag: String) {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      limit: 2000
      filter: { frontmatter: { tags: { in: [$tag] }, draft: { eq: false } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            slug
            title
            date
            tags
          }
          excerpt
        }
      }
    }
  }
`
