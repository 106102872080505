import React from "react"
import { Link } from "gatsby"
import { format } from "date-fns"
import Tag from "../Tag/Tag"

import styles from "./postrow.module.css"

const PostRow = ({ post }) => {
  const url = "/blog/" + post.frontmatter.slug
  const title = post.frontmatter.title
  const description = post.frontmatter.excerpt
  const tags = post.frontmatter.tags
  const date = format(new Date(post.frontmatter.date), "MMM d, yyyy")

  return (
    <div className={styles.post}>
      <Link to={url} className={styles.link} title={title}>
        <h2 className={styles.title}>
          <span>{title}</span>
        </h2>
      </Link>
      <p>{description}</p>
      <div className={styles.meta}>
        <span className={styles.timeRow}>
          <time dateTime={post.createdAt} className={styles.date}>
            {date}
          </time>
          in
        </span>
        <ul className={styles.tagList}>
          {tags.map(tag => (
            <li key={tag} className={styles.tagItem}>
              <Tag tag={tag} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default PostRow
